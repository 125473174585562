@charset "utf-8";


.works .inner{
    margin-top: 100px;
}

.tab_fixed{
    position: fixed;
    z-index: 900;
    width: 100%;
    background: #fff;
    left: 0;
}
.tab_fixed_inner{
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.breadCrumb{
    font-size: 14px;
    padding: 15px 0 25px;
}
.breadCrumb span{
    color: #79A6AD;
}

/* 탭 menu */

.works .tab_component{
    font-size: 18px;
    padding-bottom: 20px;
    background: #fff;
}

.works .tab_component .tab_items{
    display: flex;
    background: #79A6AD;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    
}

.works .tab_component .tab_items .tab_item{
    display: inline-block;
    flex-basis: 33.3333%;
    height: auto;
    background: transparent;
    border: 2px solid transparent;
    text-align: center;
    cursor: pointer;
    color: #fff;
}
.item_span{
    font-size: 12px;
    padding-left: 5px;
}
.works .tab_component .tab_items .tab_item > a{
    display: inline-block;
    padding: 7px 0; 
    width: 100%;
    text-decoration: none;
    border-radius: 20px;
    background: transparent;
    color: #fff;
    transition: 0.3s;
}
.works .tab_component .tab_items .tab_item > a.active{
    background: #fff;
    text-decoration: none;
    color: #79A6AD;
    transition: 0.3s;
}

/* 탭 item */

.work_items{
    padding: 100px 0 130px;
}


.work_item{
    padding: 70px 0 100px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    justify-content: space-between;
}
.work_item:last-child{
    border-bottom: 0;
    padding-bottom: 0;
}

.work_item_main{
    display: flex;
    flex-direction: column;
    padding-right: 30px;

}
.work_item_main .work_name{
    font-size: 35px;
    font-weight: 500;
    padding-left: 15px;
    position: relative;
}
.work_item_main .work_name:before{
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    width: 5px;
    height: 35px;
    background-color: #79A6AD;
}
.work_item_main > img {
    width: 100%;
    max-width: 380px;
    margin-top: 20px;
    border-radius: 5px;
}
.work_item_info{
    max-width: 720px;
    width: 100%;
    line-height: 120%;
    color: #666;
}

.work_item_info .detail{
    background: #f6f6f6;
    border-radius: 5px;
    padding: 30px;
    box-sizing: border-box;
    margin-top: 20px;
}
.work_item_info .detail.first{
    background: #f6f6f6;
    border-radius: 5px;
    padding: 30px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 300px;
    overflow: hidden;
}
.detail_flex_center{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.toggle_icon{
    cursor: pointer;
    color: #999;
    font-size: 13px;
    margin-bottom: 10px;
    
}
.toggle_icon_bt{
    cursor: pointer;
    color: #999;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
}
.toggle_icon > span{
    margin-left: 7px;
}
.toggle_icon_bt> span{
    margin-left: 7px;
}
.work_item_info .detail .detail_page {
    display: block;
    width: 100%;
    height:auto;
}
.work_item_info .detail .detail_page.bt {
    margin-top: 15px;
}

.work_item_info .detail .detail_bold {
    margin-bottom: 10px;
    font-weight: bold;
}
.work_item_info .detail .detail_bold.flex {
    margin-bottom: 10px;
    font-weight: bold;
}
.work_item_info .detail_items{
    list-style: disc;
    padding-left: 20px;
}
.work_item_info .detail_items li {
    margin-bottom: 5px;
}
.work_item_info .desc > span{
    color: #79A6AD;
}
#tag6{
    margin-top: 20px;
}

/* responsive */
@media (max-width: 1024px) {
    .works .inner{
        margin-top: 65px;
    }
    .work_item{
        padding: 70px 0 70px;
    }
}
@media (max-width: 768px) {
    .work_item{
        flex-direction: column;
        padding: 30px 0;
    }
    .work_item_main{
        padding-right: 0;
    }
    .work_item_info{
        margin-top: 20px;
    }
    .work_item_main .work_name{
        font-size: 32px;
    }
    .work_item_main .work_name:before{
        height: 32px;
    }
    
    .works .tab_component .tab_items{
        flex-wrap: wrap;
        background: transparent;
    }
    .works .tab_component .tab_items .tab_item{
        flex-grow: 1;
        flex-basis: 20%;
        background: #79A6AD;
    }
    .works .tab_component .tab_items .tab_item:nth-child(n+5){
        margin-top: 5px;
    }
    .works .tab_component .tab_items .tab_item:nth-child(1){
        border-radius: 20px 0 0 20px;
    }
    .works .tab_component .tab_items .tab_item:nth-child(4){
        border-radius: 0 20px 20px 0;
    }
    .works .tab_component .tab_items .tab_item:nth-child(5){
        border-radius: 20px 0 0 20px;
    }
    .works .tab_component .tab_items .tab_item:nth-child(7){
        border-radius: 0 20px 20px 0;
    }
    .work_item_info .desc{
        font-size: 15px;
    }
    .work_item_info .detail_items{
        font-size: 15px;
    }
    .work_item_info .detail_items li{
        margin-bottom: 2px;
    }
}
@media (max-width: 550px) {
    .work_items{
        padding-top: 143px;
    }
    .work_item{
        padding: 30px 0;
    }
    .works .tab_component .tab_items{
        font-size: 15px;
    }
}
