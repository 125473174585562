@charset "utf-8";

.modal_bg{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.2);
    z-index: 999999;
}

.modal_bg.visible{
    display: block;
}

.modal{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 80%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    overflow-y: scroll;
    border-radius: 5px;
    line-height: 110%;
}

.modal::-webkit-scrollbar {
    width: 5px;
}

.modal::-webkit-scrollbar-thumb {
    -webkit-border-radius:10px;
    border-radius: 10px;
    background:#79a6ad;
}

.modal::-webkit-scrollbar-track-piece {
    background-color: #ccc;
}

.modal.visible{
    display: block;
}

.modal .post_box_title_area{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.modal .post_box_desc{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    cursor: default;
}

.modal .post_img_area{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.modal .post_img{
    vertical-align: bottom;
    margin-left:40px;
    margin-right:40px;
    margin-bottom: 5px;
}

.modal_close_btn{
    color: #fff;
    font-size: 16px;
    display: block;
    margin: 20px auto 0;
    cursor: pointer;
    font-family: "Pretendard-Medium";
}

.modal .post_box_title_area{
    cursor: default;
}


.modal .post_box_info{display: block;}
/* .modal .post_box_info li::before{
    bottom: 2px;
} */
.modal .post_youtube_area{
    display: block;
    position: relative;
    padding-bottom: 56.25%; /* 9:16 비율 */
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
}

.modal .post_youtube_area iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.empty_image{
    display: none;
}

@media (max-width: 767px) {
    .modal .post_img{
        
    }
    .post_box_desc{
        font-size: 14px;
    }
}