@charset "utf-8";

.main {
    width: 100%;
    height: 750px;
    position: relative;

    background-color: #333;

    background: url('../img/main_intro_banner.jpg') no-repeat;
    background-position: top center;
    background-size: cover;

    transition: all 0.4s;
}

.main .txt_box {
    width: 100%;

    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    color: #fff;
    text-align: center; 

    font-family: 'NotoSerifKR';
}

.main .txt_box .main_icon {
    width: 20%;
    margin: 0 auto;
}
.main .txt_box .main_icon img {
    width: 100%;
    max-width: 130px;
    margin-bottom: 30px;

    position: relative;
    top: 20px;
    opacity: 0;
}
.main .txt_box h2 {
    font-size: 42px;

    line-height: 46px;
    margin-bottom: 40px;

    position: relative;
    top: 20px;
    opacity: 0;
}

.main .txt_box p {
    font-size: 22px;

    line-height: 32px;
    font-weight: 400;

    white-space: pre-line;

    position: relative;
    top: 20px;
    opacity: 0;
}

.show1 {
    opacity: 1 !important;
    transition: all 1.5s !important;
    top: 0 !important;
}
.show2 {
    opacity: 1 !important;
    transition: all 2s !important;
    top: 0 !important;
}
.show3 {
    opacity: 1 !important;
    transition: all 2s !important;
    top: 0 !important;
}
/* 업무분야영역 */

article.business {
    margin-top: 100px;
    background-image: url('../img/business_bg.jpg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    padding-bottom: 80px;
}


article.business .con_tit {
    width: 22%;
    float: left;

    
    box-sizing: border-box;
    padding: 20px 25px;
}


article.business .business_con_wrap::after {
    content: "";
    clear: both;
    display: block;
}

article.business .business_con_wrap li {
    width: 22%;


    padding: 5% 0%;

    margin-bottom: 5%;
    margin-left: 3%;

    float: left;
    
    /* outline: 1px solid red; */

    text-align: center;

    box-sizing: border-box;

    border-radius: 15px;
    box-shadow: 0 0px 25px rgba(0,0,0,0.1);

    background-color: #fff;

    /* display: flex;
    align-items: center;
    justify-content: center; */

    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
}

article.business .business_con_wrap li:hover {
    box-shadow: 0 0px 25px rgba(12, 82, 105, 0.3);
    transform: translateY(-3%);
}

article.business .business_con_wrap li:nth-child(4n) {
    margin-left: 0;
}



/* 이미지 변경 */

article.business .business_con_wrap li img.b_icon {
    margin-bottom: 30px;
    transition: all 0.3s;
}

article.business .business_con_wrap li:hover img.b_icon {
    filter: invert(68%) sepia(4%) saturate(2131%) hue-rotate(140deg) brightness(93%) contrast(83%);
}


/* 이미지 변경 */




article.business .business_con_wrap li a {
    box-sizing: border-box;
    text-decoration: none;
    display: block;
}

article.business .business_con_wrap li h3 {
    font-size: 32px;

    color: #333;
    text-decoration: none;
    margin-bottom: 30px;
}

article.business .business_con_wrap li .more {
    display: inline-block;

    width: 50px;
    height: 50px;

    border-radius: 50%;

    font-size: 0;
    
    

}  /********* article1. 업무분야 영역 ************/

article.business .business_con_wrap li .more img {
    transform: rotate(0deg);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    width: 100%;
}

article.business .business_con_wrap li .more:hover img {
    transform: rotate(90deg);
}

/********* article2. 구성원소개 영역 ************/

article.lawyer {
    padding-top: 120px;

    background: url('../img/lawyer_bg.jpg') no-repeat bottom/cover;
    padding-bottom: 120px;
}

article.lawyer .con_tit {
    text-align: center;

    color: #fff;
}
article.lawyer .con_tit:first-child br {
    display: none;
}

article.lawyer ul.represent {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 70px;
}

article.lawyer ul.represent::after {
    content: "";
    display: block;
    clear:both;
}

article.lawyer ul.represent li {
    width: 16.8%;
    float: left;

    border-radius: 20px;

    overflow: hidden;

    position: relative;

    transition: all 0.4s;
}

article.lawyer ul.represent li:hover {
    box-shadow: 0 0px 25px rgba(255, 255, 255, 0.2);
}

article.lawyer ul.represent li h3 { 
    width: 100%;

    position: absolute;
    bottom : 0;
    left: 0;

    background: #79a6ad9c;
    color: #fff;

    font-size: 20px;
    line-height: 50px;

    height: 50px;
    

    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
    
}

/* article.lawyer ul.represent li:hover h3 {
    line-height: 60px;
    height: 60px;
} */


article.lawyer ul.represent li~li {
    margin-left: 4%;
}

article.lawyer ul.represent li .img_box {
    width: 100%;
    padding-top: 150%; /* 1:1 aspect ratio */
    overflow: hidden;
}

article ul.represent li .img_box img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transition: all 0.4s;
}
article.lawyer ul.represent li:hover img {
    transform: scale(1.05);
}



article.lawyer .btn_wrap {

    margin-top: 50px;

    display: flex;

    align-items: center;
    justify-content: center;
}

article.lawyer .btn_wrap button a {
    width: 100%;
    font-size: 18px;
}     /********* article2. 구성원소개 영역 ************/


article.lawyer .branch_office p {
    /* outline: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

article.lawyer .branch_office p span {
    display: block;
    margin-right: 40px;

    /* outline: 1px solid red; */
}

article.lawyer .branch_office p span b {
    color: #79a6ad;
    font-size: 16px;
}
/* article3. 오시는 길 영역 */

article.location {
    width: 100%;

    background: url('../img/location_bg.jpg') no-repeat top/cover;
    padding: 100px 0 160px;
}

article.location .con_tit {
    margin-bottom: 30px;
}

article.location .content_wrap {
    width: 100%;

    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.1);

    position: relative;
}

article.location .content_wrap .list_box>li {
    display: none;
}

article.location .content_wrap .list_box>li::after {
    display: block;
    content: "";
    clear: both;
}

article.location .content_wrap .list_box>li.active {
    display: block;
}

article.location .content_wrap .list_box>li .map_box {
    width: 50%;
    height: 520px;
    float: right;
    background-color: #333;
}

article.location .content_wrap .list_box>li .map_box iframe {
    width: 100%;
    height: 520px;
}

article.location .content_wrap .list_box>li .info_box {
    width: 50%;
    height: 520px;
    float: left;
    background-color: #fff;

    padding: 60px 40px;
    box-sizing: border-box;
}

article.location .content_wrap .list_box>li .info_box dl dt {
    color: #79a6ad;
    font-size: 28px;
    margin-bottom: 20px;
}

article.location .list_box>li .info_box dl dd {
    font-size: 22px;
    line-height: 34px;
}

article.location .list_box>li .info_box p {
    margin: 20px 30px 40px;
    color: #999;
    font-size: 20px;

    position: relative;
}

article.location .list_box>li .info_box p:before {
    content: "i";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    text-align: center;
    font-size: 0.8rem;
    line-height: 20px;

    position: absolute;
    left: -28px;
}

article.location .list_box>li .info_box>ul {
    border-top: 2px solid #ddd;
    padding: 40px 0;
}


article.location .list_box>li .info_box>ul li {
    margin-bottom: 20px;
    color: #999;

    font-size: 18px;
}

article.location .list_box>li .info_box>ul li span {
    font-size: 20px;
    margin-right: 20px;

    display: inline-block;
    color: #333;
}

article.location .list_box>li .info_box>ul li span.add {
    color: #fa4040;
    display: block;
    margin-top: 20px;

    font-size: 16px;
    margin-left: 100px;
}

/* 탭메뉴 */

article.location .tab_component {
    width: 100%;

    font-size: 20px;
    position: absolute;

    bottom: -30px;
}

article.location .tab_component .tab_items {
    display: flex;
    background: #79A6AD;
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 40px;

}

article.location .tab_component .tab_items .tap_li {
    display: inline-block;
    width: 33.3%;
    background: transparent;
    text-align: center;
    padding: 20px 0;

    border: 2px solid transparent;
}

article.location .tab_component .tab_items .tap_li.active {
    background: #fff;
    border: 2px solid #79A6AD;
    border-radius: 40px;

}

article.location .tab_component .tab_items .tap_li>a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: #fff;

    transition: all 0.3s;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
}

article.location .tab_component .tab_items .tap_li.active>a {
    color: #79A6AD;
}

/**************** 반응형 **********************/

@media (max-width: 1200px) {
    article.location .list_box>li .info_box dl dt {
        font-size: 24px;
        margin-bottom: 10px;
    }

    article.location .list_box>li .info_box dl dd {
        font-size: 18px;
        line-height: 26px;
    }

}




/* 태블릿 */

@media (max-width: 1024px) {


    /* 메인배너 태블릿 */
    .main {
        margin-top: 65px;
        height: 550px;
    }

    .main .txt_box h2 {
        font-size: 28px;

        line-height: 38px;
        margin-bottom: 15px;
    }

    .main .txt_box p {
        font-size: 14px;

        line-height: 26px;
        font-weight: 400;
    }

    /* 메인배너 태블릿 끝*/

    /* 업무분야영역 태블릿 */
    article.business .business_con_wrap li {
        width: 22%;
        padding: 3% 0%;

        margin-bottom: 3%;
    }

    article.business .business_con_wrap li h3 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    article.business .business_con_wrap li img.b_icon {
        margin-bottom: 20px;
        width: 50%;
    }

    article.business .business_con_wrap li .more {
        display: inline-block;

        width: 25%;
        height: 25%;
    }

    /* 업무분야영역 태블릿  끝 */


    /* 구성원 소개 영역 태블릿 */
    article.lawyer ul.represent li h3 {
        font-size: 16px;
        height: 60px;
    }

    article.lawyer {
        padding: 50px 0;
    }

    /* 구성원 소개 영역 태블릿 끝*/

    /* 오시는길 영역 태블릿 */

    article.location {
        padding: 5% 0 10%;
    }

    article.location .content_wrap .list_box>li .map_box {
        height: 400px;
    }
    
    article.location .content_wrap .list_box>li .map_box iframe {
        height: 400px;
    }
    
    article.location .content_wrap .list_box>li .info_box {
        height: 400px;
    
        padding: 5% 4%;
    }

    article.location .content_wrap .list_box>li .info_box dl dt {
        font-size: 22px;
        margin-bottom: 20px;
    }
    article.location .content_wrap .list_box>li .info_box dl dd {
        font-size: 16px;
    }

    article.location .content_wrap .list_box >li .info_box p {
        font-size: 14px;
    }

    article.location .content_wrap .list_box >li .info_box p:before {
        width: 12px;
        height: 12px;
        font-size: 0.5rem;
        line-height: 12px;
        left: -20px;
    }

    article.location .list_box >li .info_box > ul {
        border-top: 1px solid #ddd;
        padding: 20px 0;
    }
    article.location .list_box >li .info_box > ul li {
        margin-bottom: 10px;
        font-size: 16px;
    }
    
    article.location .list_box >li .info_box > ul li span {
        font-size: 16px;
    }
    
    article.location .list_box >li .info_box > ul li span.add {
        margin-top: 20px;
        font-size: 14px;
    }



    article.location .tab_component {
        font-size: 16px;
    }

    article.location .tab_component .tab_items .tap_li {
        padding: 15px 0;
    }


}

@media (max-width: 865px) {

    article.location .content_wrap .list_box>li .info_box dl dt {
        font-size: 20px;
        margin-bottom: 20px;
    }
    article.location .content_wrap .list_box>li .info_box dl dd {
        font-size: 16px;
    }


    article.location .list_box >li .info_box > ul li {
        font-size: 14px;
    }
    
    article.location .list_box >li .info_box > ul li span {
        font-size: 14px;
    }
    
    article.location .list_box >li .info_box > ul li span.add {
        margin-left: 0;
        font-size: 14px;
    }


    article.location .tab_component {
        font-size: 15px;
    }

    article.location .tab_component .tab_items .tap_li {
        padding: 10px 0;
    }


}


/* 모바일 */
@media (max-width: 767px) {

    .inner {
        width: 90%;
        max-width: 500px !important;
    }

    /* 메인배너 모바일 */
    .main {
        margin-top: 65px;
        height: 450px;
    }

    .main .txt_box h2 {
        width: 90%;
        max-width: 500px !important;
        margin: 0 auto;
        font-size: 18px;
        line-height: 28px;

        word-break: keep-all;
    }

    .main .txt_box h2 br {
        display: none;
    }

    .main .txt_box p {
        display: none;
    }

    /* 메인배너 모바일 끝*/


    /* 업무분야영역 모바일 */
    article.business {
        margin-top: 50px;
    }

    article.business .con_tit {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    article.business .con_tit h2 {

        font-size: 24px;
        margin-bottom: 20px;
    }

    article.business .con_tit p {
        word-break: break-all;

        width: 100%;
        font-size: 16px;
        line-height: 24px;
    }

    article.business .con_tit p br {
        display: none;
    }


    article.business .business_con_wrap li {
        width: 47.5%;
        padding: 7% 0%;

        margin-bottom: 3%;
    }

    article.business .business_con_wrap li~li {
        margin-left: 5%;
    }

    article.business .business_con_wrap li:nth-child(4n) {
        margin-left: 5%;
    }

    article.business .business_con_wrap li:nth-child(2n+1) {
        margin-left: 0;
    }

    article.business .business_con_wrap li h3 {
        font-size: 18px;
        margin-bottom: 0px;
    }

    article.business .business_con_wrap li img.b_icon {
        margin-bottom: 10px;
        width: 80px;
    }

    article.business .business_con_wrap li .more {
        display: none;
        margin: 5% auto 0;
    }

    article.business .business_con_wrap li .more img {
        transform: scale(0.7);
    }

    article.business .business_con_wrap li .more:hover img {
        transform: scale(0.7);
    }

    /* 업무분야영역 모바일  끝 */


    /* 구성원 소개 영역 모바일 */

    article.lawyer .con_tit:first-child br {
        display: block;
    }

    article.lawyer .con_tit:first-child {
        width: 47.5%;

        float: left;
        text-align: left;
        color: #fff;
        margin-left: 5%;
        margin-top: 5%;
    }

    article.lawyer .con_tit:first-child h2 {
        font-size: 26px;
    }

    article.lawyer .con_tit:first-child p {
        font-size: 18px;
        line-height: 28px;
    }

    article.lawyer ul.represent li {
        width: 47.5%;
    }

    article.lawyer ul.represent li:first-child {
        margin-bottom: 5%;
    }

    article.lawyer ul.represent li~li {
        margin-left: 0%;
    }

    article.lawyer ul.represent li:nth-child(2n+1) {
        margin-left: 0%;
    }

    article.lawyer ul.represent li:nth-child(3) {
        margin-left: 5%;
        margin-bottom: 5%   ;
    }
    article.lawyer ul.represent li:nth-child(5) {
        margin-left: 5%;
    }


    article.lawyer ul.represent li h3 {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
    }

    article.lawyer ul.represent li:hover h3 {
        height: 50px;
        line-height: 50px;
    }

    article.lawyer {
        padding: 50px 0;
    }

    article.lawyer .branch_office p {
        /* outline: 1px solid red; */
        display: block;
        align-items: center;
        justify-content: center;
    }

    article.lawyer .branch_office p span {
        margin-right: 0;
        font-size: 16px;
    }
    article.lawyer .branch_office p span br{
        display: none;
    }
    article.lawyer .branch_office p span b {
        font-size: 14px;
    }

    article.lawyer .branch_office p span strong {
        display: none;
    }
    /* 구성원 소개 영역 모바일 끝*/

    /* 오시는 길 모바일 */

    article.location {
        padding: 10% 0 20%;
    }


    article.location .content_wrap .list_box>li .map_box {
        width: 100%;
        height: 30%;
        float: none;
        background-color: #fff;
    }
    
    article.location .content_wrap .list_box>li .map_box iframe {
        width: 100%;
        height: 300px;
    }
    
    article.location .content_wrap .list_box>li .info_box {
        width: 100%;
        float: none;
        height: 30%;
    
        padding: 5% 3%;
        box-sizing: border-box;
    }

    article.location .list_box >li .info_box > ul li span.add {
        margin-left: 10%;
        font-size: 14px;
    }

    article.location .tab_component {
        width: 100%;
        font-size: 11px;
    }
    article.location .tab_component .tab_items {
        width: 100%;
    }

    article.location .tab_component .tab_items .tap_li {
        padding: 8px 0;
    }

}

@media (max-width: 450px) {
    article.location .tab_component {
        font-size: 12px;
    }
}
@media (max-width: 360px) {
    article.location .tab_component {
        font-size: 11px;
    }
}
