@charset "utf-8";

.stories{
    margin-top: 100px;
}
.stories_main{
    padding-top: 30px;
    padding-bottom: 170px;
}
.stories_main_title{
    display: inline-block;
    font-family: 'NotoSerifKR';
    font-size: 40px;
    position: relative;
    padding-left: 20px;
    margin-bottom: 50px;
    
}
.stories_main_title::before{
    display: inline-block;
    content: "";
    width: 5px;
    height: 42px;
    background: #79A6AD;
    position: absolute;
    bottom: -5px;
    left: 0;
}


/* grid button */
.grid_btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}

.grid_btn .grid_btn_sep1{
    width: 24px;
    height: 24px;
    background: #ddd;
    margin-right: 15px;
    cursor: pointer;
}
.grid_btn .grid_btn_sep1:hover{
    background: #79A6AD;
}
.grid_btn .grid_btn_sep1.active{
    background: #79A6AD;    
}
.grid_btn .grid_btn_sep2{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    cursor: pointer;
}
.grid_btn .grid_btn_sep2 .sep2_box{
    background: #ddd;
}
.grid_btn .grid_btn_sep2:hover .sep2_box{
    background: #79A6AD;
}
.grid_btn .grid_btn_sep2 .left{
    flex-basis: 45%;
}
.grid_btn .grid_btn_sep2 .right{
    flex-basis: 45%;
}
.grid_btn .grid_btn_sep3{
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.grid_btn .grid_btn_sep3 .top{
    flex-basis: 45%;
    display: flex;
    justify-content: space-between;

}
.grid_btn .grid_btn_sep3 .top .left{
    flex-basis: 45%;  
}
.grid_btn .grid_btn_sep3 .top .right{
    flex-basis: 45%;  
}
.grid_btn .grid_btn_sep3 .bottom{
    flex-basis: 45%;
    display: flex;
    justify-content: space-between;
}
.grid_btn .grid_btn_sep3 .bottom .left{
    flex-basis: 45%;  
}
.grid_btn .grid_btn_sep3 .bottom .right{
    flex-basis: 45%;  
}
.grid_btn .grid_btn_sep3 .sep3_box{
    background: #ddd;
}
.grid_btn .grid_btn_sep3:hover .sep3_box{
    background: #79A6AD
}

/* post box grid*/
.post_box_list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: 380px 380px 380px; */
    column-gap: 30px;
    row-gap: 50px;
    line-height: 110%;
}
.post_box_list.sep2{
    grid-template-columns: repeat(2, 1fr);
}
.post_box_list.sep1{
    grid-template-columns: repeat(1, 1fr);
}
.post_box{
    /* align-self: start; */
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
}
@keyframes fadeIn {
    from{
        opacity: 0;
        transform: translateY(50px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

.post_box_title_area{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    cursor: pointer;
}
.post_box_title{
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.post_box_list.sep1 .post_box_title{
    margin-bottom: 8px;
}
.modal .post_box_title{
    margin-bottom: 8px;
}
.post_link{
    width: 100%;
    word-break: break-all;
    margin-top: 10px;
}
.post_link a{
    font-size: 15px;
    color: #999;
    font-weight: normal;
}
.post_link a:hover{
    color: #79A6AD;
}
.post_box_info{
    display: none;
    font-size: 15px;
    margin-top: 5px;
}
.post_box_info li{
    display: inline-block;
    position: relative;
    padding-left: 10px;
    margin-right: 10px;
}
.post_box_info li::before{
    content: "";
    width: 5px;
    height: 12px;
    background: #79A6AD;
    position: absolute;
    left: 0;
    top: 2px;
}

.post_box_list.sep2 .post_box_info{
    display: none;
}
.post_box_list.sep1 .post_box_info{
    display: block;
}

.post_box_content{
    padding-bottom: 40px;
}
.post_box_list .post_box_desc{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; 
    -webkit-box-orient: vertical;
    white-space: pre-line;
    cursor: pointer;

    width: 100%;
    word-break: keep-all;
    /* font-size: 0; */
}
.post_box_list.sep1 .post_box_desc{
    display: block;
    
}
.post_box_desc span{
    font-weight: 600;
}
.post_box_list .post_img_area{
    margin-bottom: 20px;
}
.post_box_list .post_img_area{
    display: none;
}
.post_box_list.sep2 .post_img_area{
    display: flex;
    justify-content: center;
    align-items: center;

}
.post_box_list.sep1 .post_img_area{
    display: flex;
    justify-content: center;
    align-items: center;
}
.post_box_list.sep2 .post_img{
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 150px;
    height: 90px;
}
.post_box_list.sep1 .post_img{
    display: inline-block;
    margin-left:40px;
    margin-right:40px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.post_img:first-child{margin-right: 10px;}
/* 유튜브 */
.post_box_list .post_youtube_area{
    display: none;
}
.post_box_list.sep2 .post_youtube_area{
    display: none;
}
.post_box_list.sep1 .post_youtube_area{
    display: block;
    position: relative;
    padding-bottom: 56.25%; /* 9:16 비율 */
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
}
.post_box_list.sep1 .post_youtube_area iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* top_tag */
.post_box_top_tag{
    color: #79A6AD;
    margin-bottom: 10px;
    font-size: 16px;
}
.post_box_top_tag li{
    display: inline-block;
}
.post_box_list .post_box_top_tag{
    display: none;
}
.post_box_list.sep2 .post_box_top_tag{
    display: none;
}
.post_box_list.sep1 .post_box_top_tag{
    display: block;
}
.news .post_box_list.sep1 .post_box_top_tag{
    display: none;
}
.post_box_member{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
.post_box_member .member_face{
    width: 85px;
    border-radius: 50%;
    background: #ddd;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
    margin-right: 20px;
}
.post_box_member .member_name > span{
    font-weight: bold;
}

.post_box_list.sep1 .post_box_member{
    display: none;
}

.post_box_list.sep1 .post_box_detail{
    display: none;
}
.post_box_detail{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    cursor: pointer;
}
.post_box_detail .more_btn{
    position: relative;
}
.post_box_detail .more_btn:after{
    position: absolute;
    content: "";
    width: 50px;
    height: 1px;
    background: #79A6AD;
    border-radius: 10px;
    left: 68px;
    bottom: 5px;
}
.viewMore{
    display: block;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    margin-top: 70px;
    font-family: "Pretendard-Medium";
    cursor: pointer;
}

/* 검색 */
.search{
    margin-bottom: 25px;
    text-align: right;
    color: #79A6AD;
}
.search input{
    border: 1px solid #79A6AD;
    max-width: 250px;
    width: 100%;
    padding: 5px 15px;
    margin-right: 10px;
    font-family: "Pretendard-Medium";
    font-size: 16px;
}
.search input::placeholder{
    color: #ddd;
}
.search button{
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    color: #79A6AD;
    background: none;
    border: none;
}
.refreshBtn{
    margin-left: 10px;
}
.search input:focus{
    outline: 2px solid #79A6AD;
}
.nothing{
    text-align: center;
    font-size: 20px;
    padding: 120px 0;
}

/* reaponsive */
/* 태블릿 */

@media (max-width: 1024px) {
    .stories{
        margin-top: 65px;
    }
    .grid_btn .grid_btn_sep3{
        display: none;
    }
}
/* 모바일 */
@media (max-width: 767px) {
    .grid_btn{
        display: none;
    }
    .post_box_title{
        display: block;
        font-size: 16px;
    }
    .post_box_content{
        padding-bottom: 0;
    }
    .post_box_list.sep1 .post_box_desc{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 14px;
    }
    .post_box_list.sep1 .post_img{
        max-width: 200px;
        width: 100%;
    }
    .post_box_info li {display: block;}
    .search{text-align: center;}
}