@charset "utf-8";

.side_menu {
    position: fixed;

    top: 250px;
    right: 0;

    text-align: center;
}

.side_menu nav .img-wrap {
    height: 50px;
}

.side_menu nav .img-wrap img {
    margin-top: 5px;
}

.side_menu nav a {
    background-color: #79a6adb9;
    color: #fff;
    text-decoration: none;
    display: block;

    padding: 15px 10px;

    transition: all .3s;
    line-height: 20px;

    position: relative;
}

.side_menu nav a::before {
    content: "";
    display: block;
    width: 80%;
    height:1px;
    background-color: #ffffff2f;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.side_menu nav li:first-child a:before {
    display: none;
}



.side_menu nav a span {
    font-size: 14px;
}

.side_menu nav li:first-child a {
    border-top-left-radius: 10px;
}
.side_menu nav li:last-child a {
    border-bottom-left-radius: 10px;
}

.side_menu nav a:hover {
    background-color: #79a6ad;
}

@media (max-width: 1450px) {
    .side_menu {
        display: none;
    }

}