@charset "utf-8";


article.s_location {
    width: 100%;
    padding: 100px 0 160px;
}

article.s_location .breadCrumb {
    margin-top: 0;
}


article.s_location .title h2 {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-family: 'NotoSerifKR';
    font-size: 40px;
    padding: 40px 0;
}


article.s_location .list_box {
    width: 100%;
    position: relative;
    /* li의 기준 */
}

article.s_location .content_wrap {
    margin-top: 100px;
    width: 100%;

    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.1);

    position: relative;
}


article.s_location .list_box>li {
    display: none;
}

article.s_location .content_wrap .list_box>li::after {
    display: block;
    content: "";
    clear: both;
}


article.s_location .list_box>li.active {
    display: block;
}


article.s_location .list_box>li .map_box .map {
    width: 50%;
    height: 520px;
    float: right;
    background-color: #333;
}

article.s_location .content_wrap .list_box>li .map_box iframe {
    width: 100%;
    height: 520px;
}

article.s_location .list_box>li .info_box {
    width: 50%;
    height: 520px;
    float: left;
    background-color: #fff;

    padding: 60px 40px;
    box-sizing: border-box;
}

article.s_location .list_box>li .info_box dl dt {
    color: #79a6ad;
    font-size: 28px;
    margin-bottom: 20px;
}

article.s_location .list_box>li .info_box dl dd {
    font-size: 22px;
    line-height: 34px;
}

article.s_location .list_box>li .info_box p {
    margin: 20px 30px 40px;
    color: #999;
    font-size: 20px;

    position: relative;
}

article.s_location .list_box>li .info_box p:before {
    content: "i";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;
    text-align: center;
    font-size: 0.8rem;
    line-height: 20px;

    position: absolute;
    left: -28px;
}

article.s_location .list_box>li .info_box>ul {
    border-top: 2px solid #ddd;
    padding: 40px 0;
}

article.s_location .list_box>li .info_box>ul li {
    margin-bottom: 20px;
    color: #999;

    font-size: 18px;
}

article.s_location .list_box>li .info_box>ul li span {
    font-size: 20px;
    margin-right: 20px;

    display: inline-block;
    color: #333;
}

article.s_location .list_box>li .info_box>ul li span.add {
    color: #fa4040;
    display: block;
    margin-top: 20px;

    font-size: 16px;
    margin-left: 100px;
}

/* 탭 menu css*/

article.s_location .s_location_tab {
    width: 100%;
    margin: 0 auto;
    font-size: 18px;
    margin-top: 20px;
    padding-bottom: 60px;
    border-bottom: 2px solid #ddd;
}

article.s_location .s_location_tab .s_location_tab_items {
    display: flex;
    background: #79A6AD;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 40px;
    cursor: pointer;
}

article.s_location .s_location_tab .s_location_tab_items .tap_li {
    display: inline-block;
    width: 33.3%;
    background: transparent;
    text-align: center;
    padding: 8px 0;

    border: 2px solid transparent;
}

article.s_location .s_location_tab .s_location_tab_items .tap_li>a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: #fff;

    transition: all 0.3s;
    -webkit-transition: all 0.4s;
    -ms-transition: all 0.4s;
}


article.s_location .s_location_tab .s_location_tab_items .tap_li.active {
    background: #fff;
    border: 2px solid #79A6AD;
    border-radius: 40px;
}

article.s_location .s_location_tab .s_location_tab_items .tap_li.active>a {
    color: #79A6AD;
}



article.s_location .list_box>li .map_box iframe {
    pointer-events: all;
}

/* tablet */

/**************** 반응형 **********************/

@media (max-width: 1200px) {
    article.s_location .list_box>li .info_box dl dt {
        font-size: 24px;
        margin-bottom: 10px;
    }

    article.s_location .list_box>li .info_box dl dd {
        font-size: 18px;
        line-height: 26px;
    }

}

@media (max-width: 1024px) {
    article.s_location .breadCrumb {
        margin-top: -30px;
    }
}



@media (max-width: 1000px) {

    article.s_location {
        width: 100%;
        padding: 100px 0 100px;
    }

    article.s_location .content_wrap {
        margin-top: 60px;
    }
    article.s_location .list_box>li .map_box .map {
        height: 450px;
    }

    article.s_location .content_wrap .list_box>li .map_box iframe {
        height: 450px;
    }

    article.s_location .list_box>li .info_box {
        height: 450px;
        padding: 50px 30px;
    }


    article.s_location .list_box>li .info_box dl dt {
        font-size: 22px;
        margin-bottom: 10px;
    }

    article.s_location .list_box>li .info_box dl dd {
        font-size: 16px;
        line-height: 26px;
    }

    article.s_location .list_box>li .info_box p {
        font-size: 16px;
    }

    article.s_location .content_wrap .list_box>li .info_box p:before {
        width: 14px;
        height: 14px;
        font-size: 0.5rem;
        line-height: 14px;
        left: -20px;
    }

    article.s_location .list_box>li .info_box>ul li span {
        font-size: 16px;
    }

    article.s_location .list_box>li .info_box>ul li span.add {
        margin-top: 20px;
        font-size: 14px;
        margin-left: 0;
    }

}

@media (max-width: 900px) {
    article.s_location .list_box>li .map_box .map {
        width: 100%;
        float: none;
        background-color: #333;
    }

    article.s_location .content_wrap .list_box>li .map_box iframe {
        width: 100%;
    }

    article.s_location .list_box>li .info_box {
        width: 100%;
    }

}







@media (max-width: 767px) {

    article.s_location .list_box>li .info_box dl dt {
        font-size: 22px;
        margin-bottom: 10px;
    }

    article.s_location .list_box>li .info_box dl dd {
        font-size: 16px;
        line-height: 26px;
    }

    article.s_location .list_box>li .info_box p {
        font-size: 16px;
    }

    article.s_location .content_wrap .list_box>li .info_box p:before {
        width: 14px;
        height: 14px;
        font-size: 0.5rem;
        line-height: 14px;
        left: -20px;
    }

    article.s_location .list_box>li .info_box>ul {
        padding: 30px 0;
    }

    article.s_location .list_box>li .info_box>ul li {
        font-size: 16px;
    }

    article.s_location .list_box>li .info_box>ul li span {
        font-size: 16px;
    }

    article.s_location .list_box>li .info_box>ul li span.add {
        margin-top: 20px;
        font-size: 14px;
        margin-left: 0;
    }

    /* 탭 menu css*/

    article.s_location .s_location_tab {
        margin-top: 0px;
        padding-bottom: 40px;
        border-bottom: 1px solid #ddd;
    }

    article.s_location .s_location_tab .s_location_tab_items {
        width: 100%;
        border-radius: 40px;
    }

    article.s_location .s_location_tab .s_location_tab_items .tap_li {
        display: inline-block;
        width: 33.3%;
        background: transparent;
        text-align: center;
        padding: 8px 0;

        border: 2px solid transparent;
    }
    article.s_location .s_location_tab ul li {
        font-size: 16px;
    }

}

@media (max-width: 500px) {

    article.s_location .list_box>li .map_box .map {
        height: 330px;
    }

    article.s_location .content_wrap .list_box>li .map_box iframe {
        height: 330px;
    }

    article.s_location .list_box>li .info_box {
        height: 420px;
    }


    article.s_location .s_location_tab {
        font-size: 14px;
    }

    article.s_location .list_box>li .info_box dl dt {
        font-size: 18px;
        margin-bottom: 10px;
    }

    article.s_location .list_box>li .info_box dl dd {
        font-size: 14px;
        line-height: 26px;
    }

    article.s_location .list_box>li .info_box p {
        font-size: 14px;
    }

    article.s_location .content_wrap .list_box>li .info_box p:before {
        width: 12px;
        height: 12px;
        font-size: 0.5rem;
        line-height: 12px;
        left: -20px;
    }

    article.s_location .list_box>li .info_box>ul {
        padding: 30px 0;
    }

    article.s_location .list_box>li .info_box>ul li {
        font-size: 14px;
    }

    article.s_location .list_box>li .info_box>ul li span {
        font-size: 14px;
    }

    article.s_location .list_box>li .info_box>ul li span.add {
        margin-top: 20px;
        font-size: 14px;
        margin-left: 0;
    }
    article.s_location .s_location_tab ul li {
        font-size: 12px;
    }

}


@media (max-width: 375px) {

    article.s_location .s_location_tab ul li {
        font-size: 11px;
    }


}