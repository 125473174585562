@charset "utf-8";

.asks .top {
    margin-top: 100px;
    width: 100%;
    height: 200px;
    background-color: #fff;

    position: relative;
}

.asks .top h2 {
    font-size: 40px;
    font-family: 'NotoSerifKR';
    position: absolute;
    top: 50%;
    left :50%;
    transform: translate(-50%, -50%);
}
.asks .top .notice {
    color: #f00;

    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    font-size: 14px;
}

.asks .email_frm label span {
    color: #f00;
    font-size: 14px;
    vertical-align: top;
}

.asks .email_frm {
    width: 100%;
}

.asks .email_frm fieldset {
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    padding: 40px 0px;
    box-sizing: border-box;
}

.asks .email_frm fieldset legend {
    display: none;
}

.asks .email_frm fieldset .sheet {
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
}

.asks .email_frm fieldset .sheet:last-child {
    border-bottom: none;
}

.asks .email_frm fieldset .sheet label {
    font-size: 18px;
    display: inline-block;
    width: 15%;
}
.asks .email_frm fieldset .sheet input {
    width: 30%;
    height: 40px;
    border: 1px solid #ccc;
    padding: 0 15px;
}
.asks .email_frm fieldset .sheet input::placeholder {
    font-family: "Pretendard-Medium";
    color: #ccc;
    font-size: 14px;
}

.asks .email_frm fieldset .sheet input:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;
}

.asks .email_frm fieldset .sheet select {
    width: 20%;
    height: 40px;
    border: 1px solid #ccc;

    font-family: "Pretendard-Medium";
    padding-left: 15px;
    color: #000;
    
    font-size: 14px;
}
.asks .email_frm fieldset .sheet select:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;
}

.asks .email_frm fieldset .sheet select option {
    padding: 0 15px;
    font-family: "Pretendard-Medium";
    color: #333;
}

.asks .email_frm fieldset .sheet textarea {
    width: 80%;
    height: 350px;
    border: 1px solid #ccc;
    vertical-align: top;

    resize: none;

    padding: 15px;
    font-family: "Pretendard-Medium";
    font-size: 16px;
}

.asks .email_frm fieldset .sheet textarea:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;

    padding: 15px;
    font-family: "Pretendard-Medium";
    font-size: 16px;
}

.asks .email_frm .privacy {
    width: 100%;
    display: flex;
}
.asks .email_frm .privacy label {
    display: block;
    width: 50%;
    text-align: right;
    box-sizing: border-box;
    margin-top: 30px;
    padding-right: 50px;
    line-height: 30px;
}
.asks .email_frm .privacy label .chk {
    margin-right: 10px;
}
.asks .email_frm .privacy .pop-up-btn {
    display: block;
    width: 50%;
    text-align: left;
    box-sizing: border-box;
    margin-top: 30px;
    padding-left: 50px;
    line-height: 30px;
}

.asks .email_frm .privacy .pop-up-btn a {
    color: #333;
}
.asks .email_frm .privacy .pop-up-btn a:hover {
    color: #79a6ad
}


input[type="submit"] {
    display: block;
    margin: 50px auto;
    margin-bottom: 100px;

    background-color: #79a6ad; 
    color: white;

    font-size: 18px; 
    padding: 5px 25px;

    border: none; 
    cursor: pointer; 

    border-radius: 30px;

    text-align: center; 
    transition: 0.3s; 

    font-family: "Pretendard-Medium";
}


/* 개인정보처리방침 */


.pop-up::-webkit-scrollbar {
    width: 3px;
}

.pop-up::-webkit-scrollbar-thumb {
    -webkit-border-radius:10px;
    border-radius: 10px;
    background:#79a6ad;
}
.pop-up::-webkit-scrollbar-track-piece {
    background-color: #ccc;
}

.bg {
    display: none;
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.2);

    z-index: 9999999;
}
.bg.visible {
    display: block;
}

.pop-up {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;

    width: 50%;
    max-width: 550px;
    height: 80%;
    max-height: 720px;
    transform: translate(-50%, -50%);
    background-color: #fff;

    padding: 40px 30px;

    overflow-y: scroll;
    text-overflow: ellipsis;
    outline: 1px solid #79a6ad;
}

.pop-up.visible {
    display: block;
}

.pop-up h3 {
    margin-top: 15px;
    font-size: 28px;
    margin-bottom: 50px;
    display: inline-block;
    position: relative;
}

.pop-up h3::after {
    position:absolute;
    top: 40px;

    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: #79a6ad;
}

.pop-up h4 {
    font-size: 20px;
    margin-bottom: 15px;
}

.pop-up p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
}

.pop-up p~p {
    margin-left: 25px;
}

.pop-up button {
    display: block;
    margin: 0 auto;
    color: #fff;
    font-family: "Pretendard-Medium";
    font-size: 16px;

    padding: 8px 25px;

    cursor: pointer;
}


/* responsive */

@media (max-width: 1024px) {
    .asks .top {
        margin-top: 65px;
    }
}

@media (max-width: 767px) {
    
    .asks .top {
        height: 150px;
    }

    .asks .top h2 {
        font-size: 30px;
    }
    .asks .top .notice {
        font-size: 12px;
    }
    
    .asks .email_frm label span {
        color: #f00;
        font-size: 14px;
        vertical-align: top;
    }
    
    .asks .email_frm {
        width: 100%;
    }
    
    .asks .email_frm fieldset {
        padding: 10px 0px;
    }

    .asks .email_frm fieldset .sheet {
        padding: 20px 0;
    }
    
    .asks .email_frm fieldset .sheet label {
        font-size: 16px;
        width: 20%;
    }

    .asks .email_frm fieldset .sheet input {
        width: 65%;
        height: 35px;
    }
    
    .asks .email_frm fieldset .sheet select {
        width: 30%;
        height: 35px;
    }
    
    .asks .email_frm fieldset .sheet textarea {
        width: 65%;
        height: 280px;
        font-size: 14px;
        padding: 0 15px;
    }
    
    .asks .email_frm fieldset .sheet textarea:focus {
        padding: 0 15px;
        font-size: 14px;
    }
    
    .asks .email_frm .privacy {
        width: 100%;
        display: block;
    }

    .asks .email_frm .privacy::after {
        content: "";
        display: block;
        clear: both;
    }


    .asks .email_frm .privacy label {
        display: block;
        width: 75%;
        text-align: left;
        
        margin-top: 20px;
        padding-right: 0px;
        line-height: 20px;

        font-size: 16px;

        float: left;
    }

    .asks .email_frm .privacy label .chk {
        margin-right: 10px;
    }
    .asks .email_frm .privacy .pop-up-btn {

        display: block;
        width: 20%;
        text-align: center;
        box-sizing: border-box;
        margin-top: 20px;
        padding-left: 0px;
        line-height: 20px;

        box-sizing: border-box;

        float: left;
    }
    
    .asks .email_frm .privacy .pop-up-btn a {
        color: #333;

        font-size: 16px;
    }
    
    
    input[type="submit"] {
        margin: 40px auto;
        margin-bottom: 80px;
        font-size: 16px; 
        padding: 5px 20px;
    }
    
    
    /* 개인정보처리방침 */
    
    .bg {
        display: none;
        position: fixed;
        top: 0;
        height: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0, 0.2);
    
        z-index: 9999999;
    }
    .bg.visible {
        display: block;
    }
    
    .pop-up {
    
        width: 80%;
        max-width: 1200px;
        height: 60%;
    
        padding: 30px 20px;
    
        overflow-y: scroll;
        text-overflow: ellipsis;
    }
    



    .pop-up.visible {
        display: block;
    }
    
    .pop-up h3 {
        margin-top: 10px;
        font-size: 24px;
        margin-bottom: 40px;
    }
    
    .pop-up h3::after {
        top: 25px;
    }
    
    .pop-up h4 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    
    .pop-up p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 25px;
    }
    
    .pop-up p~p {
        margin-left: 20px;
    }
    
    .pop-up button {
        display: block;
        margin: 0 auto;
        color: #fff;
        font-family: "Pretendard-Medium";
        font-size: 14px;
    
        padding: 5px 20px;
    
        cursor: pointer;
    }

}