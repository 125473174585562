@charset "utf-8";

.login-wrap {
    min-height: 70vh;
}

.login-frm {
    width: 500px;
    margin: 150px auto 100px;

    height: 600px;

    background-color: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

    border-radius: 30px;
}

.login-frm h2 {
    text-align: center;

    font-family: "NotoSerifKR";
    font-size: 30px;
    padding-top: 180px;

    background: url('../img/logo150px.png') no-repeat center;
    background-size: 15%;
    margin-bottom: 10px;
}

.login-frm label {
    font-size: 0;
}

.login-frm input {
    display: block;
    width: 70%;
    height: 5%;
    margin: 40px auto 0;

    border: none;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding: 10px;
    padding-left: 50px;

    font-size: 20px;

    font-family: "Pretendard-Medium";
}

.login-frm input:focus {
    border: none;
    border-bottom: 2px solid #79a6ad;
    outline: none;
}

.login-frm input[type="text"] {
    background-image: url('../img/id_icon.png');
    background-repeat: no-repeat;
    background-position: 10px -3px;
}   

.login-frm input[type="text"]:focus {
    background-image: url('../img/id_icon_f.png');
}
.login-frm input[type="password"] {
    background-image: url('../img/pw_icon.png');
    background-repeat: no-repeat;
    background-position: 10px -3px;
}
.login-frm input[type="password"]:focus {
    background-image: url('../img/pw_icon_f.png');
}

.login-frm button[type="submit"] {
    display: block;
    width: 70%;
    height: 8%;
    margin: 70px auto;
    border-radius: 8px;

    color: #fff;
    font-size: 20px;

    cursor: pointer;

    font-family: "Pretendard-Medium";
}