@charset "utf-8";

/* bottom_tag */
.post_box_list.sep1 .post_box_bottom_tag{
    display: none;
}
.post_box_bottom_tag{
    margin-top: 10px;
    word-wrap: break-word;
}
.post_box_bottom_tag li{
    display: inline-block;
    padding: 3px 20px;
    background: #79A6AD;
    color: #fff;
    border-radius: 20px;
    margin-bottom: 3px;
}
.post_box_bottom_tag li:not(:last-child){
    margin-right: 5px;
}

.post_box_top_tag li{
    margin-right: 5px;
}