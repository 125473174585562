@charset "utf-8";


.members .inner{
    padding-top: 100px;
}

.tab_fixed{
    position: fixed;
    z-index: 900;
    width: 100%;
    background: #fff;
    left: 0;
}
.tab_fixed_inner{
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.sub .breadCrumb{
    font-family: "Pretendard-Medium"

}
.sub .breadCrumb span{
    font-weight: 400;
}

/* 탭 menu */

.members .tab_component{
    padding-bottom: 20px;
    font-size: 18px;
}

.members .tab_component .tab_items{
    display: flex;
    background: #79A6AD;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 20px;
    
}

.members .tab_component .tab_items .tab_item{
    display: inline-block;
    width: 33.3%;
    height: auto;
    background: transparent;
    border: 2px solid transparent;
    text-align: center;
    cursor: pointer;
    color: #fff;
}
.members .tab_component .tab_items .tab_item > a{
    display: inline-block;
    padding: 7px 0; 
    width: 100%;
    text-decoration: none;
    border-radius: 20px;
    background: transparent;
    color: #fff;
    transition: 0.3s;
}
.members .tab_component .tab_items .tab_item > a.active{
    background: #fff;
    text-decoration: none;
    color: #79A6AD;
    transition: 0.3s;
}

/* 탭 item */
.member_items{
    margin: 120px 0 130px;
}

.member_item{
    padding: 70px 0;
    border-bottom: 1px solid #e9e9e9;
    overflow: hidden;
    position: relative;
}
.member_item:last-child{
    border-bottom: 0;
}
.member_flex{
    display: flex;
    justify-content: left;
}
.phrase{
    font-size: 37px;
    font-family: 'NotoSerifKR', sans-serif;
    color: #79A6AD;
    text-align: center;
    margin-bottom: 70px;
    line-height: 3rem;

    height: 90px;
    vertical-align: middle;
}
.member_item_main{
    margin-right: 20px;
}
.member_img_wrap{
    width: 100%;
}
.member_img_wrap > img {
    display: block;
    width: 340px;
    position: relative;

    box-sizing: border-box;
    padding-top: 10px;
}
.member_img_wrap:before {
    position: absolute;
    left: 0;
    top: 282px;
    content: "";
    width: 180px;
    height: 443px;
    background: #79A6AD;
}

.member_img_wrap:nth-child(3n)::before {
    top: 289px;
}

.member_item_info{
    margin-top: 20px;
    line-height: 120%;
    color: #666;
}
.member_item_info .member_name{
    font-size: 47px;
    font-weight: 500;
    padding-bottom: 12px;
    margin-bottom: 20px;
    display: inline-block;
    background: url(../img/underline.png) repeat-x bottom;
    
}
.member_item_info .contact{
    margin-bottom: 40px;
    font-size: 18px;
}
.member_item_info .contact > span{
    color: #79A6AD;
    font-weight: bold;
    margin-right: 6px;
}

.detail_flex{
    display: flex;
}
.detail_flex .detail_lf{
    padding-right: 70px;
}


.member_detail{
    color: #666;
}

.member_detail > h3{
    font-size: 25px;
    color: #333;
    padding-bottom: 15px;
}
.member_detail.edu{
    margin-bottom: 40px;
}
.phrase
.member_detail ul{
    padding-left: 20px;
}
.member_detail li{
    list-style: disc;
    display: list-item;
    /* padding-left: 5px; */
    margin-left: 20px;
}

/* responsive */
@media (max-width: 1024px) {
    .members .inner{
        padding-top: 65px;
    }
    /* .member_flex{
        justify-content: space-between;
    } */
    .member_item{
        padding: 50px 0;
    }
    .member_item_main{
        margin-right: 0;
    }
    .member_img_wrap > img {
        width: 270px;
        /* padding: 10px;
        padding-bottom: 0;
        margin-bottom: 10px; */
    }
    .member_img_wrap:before {
        top: 176px;
        width: 150px;
        height: 334px;
    }
    .phrase{
        font-size: 34px;
        margin-bottom: 40px;
        height: auto;
    }
    .member_item_info .member_name{
        font-size: 42px;
    }

}
@media (max-width: 767px) {
    .member_item{
        padding: 30px 0;
    }
    .phrase{
        font-size: 24px;
        margin-bottom: 25px;
    }
    .member_flex{
        display: block;
    }
    .member_img_wrap{
        text-align: center;
    }
    .member_img_wrap > img {
        display: inline-block;
        border-radius: 10px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    }
    .member_img_wrap:before {
        display: none;
    }
    .member_item_info{
        text-align: center;
    }
    .member_item_info .member_name{
        display: inline-block;
    }
    .detail_flex{text-align: start;}
    /* .member_item:first-child{
        padding: 25px 0;
    } */
}
@media (max-width: 550px){
    .detail_flex{
        flex-direction: column;
    }
    .detail_flex .detail_lf{
        padding-right: 0;
    }
    .members .tab_component .tab_items .tab_item > a{
        font-size: 15px;
        word-break: keep-all;
    }
    .member_detail > p{
        font-size: 15px;
    }
    .member_detail li{
        font-size: 15px;
    }
    .member_detail.mainWork{
        margin-bottom: 40px;
    
    }
    .member_img_wrap > img{
        width: 190px;
    }
    .member_item_info .member_name{
        font-size: 34px;
        padding-bottom: 8px;
    }
}
