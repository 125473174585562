/* footer.css */
@charset "utf-8";

footer{
    width: 100%;
    height: auto;
    padding: 20px 0 !important;
    font-size: 14px !important;
    color: #555;
    line-height: 120% !important;
    background-color: #cecece;
}
footer .inner{
    /* padding: 0 100px; */
    display: flex;

    /* justify-content: space-between; */

    /* box-sizing: border-box; */
    
}
footer .footer_logo{
    display: inline-block;
    max-width: 94px;
    max-height: 94px;

    margin: 0 5%;
}

footer .address_li address{
    display: inline-block;

}
footer .copyright{
    margin-top: 10px;
}

footer .copyright a {
    color: #333;
    text-decoration: none;

    cursor: text;
}

/* 반응형 푸터디자인 */

/* 태블릿 */
@media (max-width: 1024px) {

    footer{
        width: 100%;
        height: auto;

        padding: 20px 0 !important;
        font-size: 13px !important;
        color: #555;
        line-height: 120% !important;
        background-color: #cecece;
    }

    footer .inner{
        display: flex;
    }
    footer .footer_logo{
        display: inline-block;
        max-width: 94px;
        max-height: 94px;
    
        margin: 0 5%;
    }
    footer .address_li {
        margin-bottom: 12px;
    }
    
    footer .address_li address{
        display: inline-block;
    
    }
    footer .copyright{
        margin-top: 10px;
    }

    footer .copyright a {
        pointer-events: none;
    }

}
@media (max-width: 767px) {
    footer .inner{
        display: block;
    }

    footer .footer_logo{
        display: block;

        max-width: 100px;
        max-height: 100px;
    
        margin: 5% auto;
    }
    footer .address_li {
        display: block;
        font-size: 12px;
        margin-bottom: 20px;
    }
    
    footer .address_li address{
        display: inline;
    }
    footer .copyright{
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        margin-bottom: 40px;
    }
}
