/* top */
.breadCrumb > a{
    color: #333;
    text-decoration: none;
}

.intro .breadCrumb > a{
    color: #fff;
}
.intro{
    margin-top: 100px;
    font-family: 'NotoSerifKR';
}
.intro .breadCrumb{
    color: #fff;
    font-family: "Pretendard-Medium"

}
.breadCrumb > span{
    font-weight: 400;
}

.intro_top{
    height: 400px;
    background: url(../img/intro_main.jpg) no-repeat bottom/ cover;
    color: #fff;
    margin-bottom: 120px;

}
.intro .title{
    text-align: center;
    width: 100%;
    margin-top: 50px;
}
.intro .title .title_name1{
    font-size: 60px;
    font-family: serif;
    font-weight: bold;
    margin-bottom: 5px;
    letter-spacing: 10px;
}
.intro .title .title_name2{
    font-size: 20px;
}
.intro .title .title_name2 > span:last-child{
    display: inline-block;
    margin: 0 20px;
    margin-bottom: 30px;
}
.intro .title .title_name3{
    line-height: 170%;
    font-size: 18px;
}

/* main */
.intro_main{
    width: 100%;
    margin-bottom: 170px;
}
.intro_box{
    display: flex;
    align-items: center;
    margin-bottom: 200px;
}
.intro_box.rg .img_box{
    order: 2;
}

.intro_box.rg .txt_box{
    text-align: right;

}
.img_box{
    width: 50%;
    padding: 0 60px;
    box-sizing: border-box;
    
}
.img_box > img{
    width: 100%;
    border-radius: 20px;
    
}
.txt_box{
    width: 50%;
}
.txt_box2{
    width: 55%;
}
.txt_box span{
    color: #79A6AD;
}
.txt_box .txt_name{
    font-size: 30px;
    margin-bottom: 83px;
    line-height: 120%;
}
.txt_box .txt_detail{
    line-height: 170%;
    font-size: 18px;
}
.btn_asks_wrap{
    width: 100%;
    
}
.btn_asks{
    display: block;
    margin: 0 auto;
}
.btn_asks a{
    font-size: 18px;
}

@media (max-width: 1200px) {
    

/* main */
.intro_main{
    width: 100%;
    margin-bottom: 170px;
}

.intro_box{
    display: flex;
    align-items: center;
    margin-bottom: 150px;
}
.intro_box.rg .img_box{
    order: 2;
}

.intro_box.rg .txt_box{
    text-align: right;
}

.img_box{
    width: 50%;
    padding: 0 60px;
    box-sizing: border-box;
    
}
.img_box > img{
    width: 100%;
    border-radius: 20px;
}
.txt_box{
    width: 50%;
}
.txt_box span{
    color: #79A6AD;
}
.txt_box .txt_name{
    font-size: 24px;
    margin-bottom: 50px;
    line-height: 32px;
}
.txt_box .txt_detail{
    line-height: 170%;
    font-size: 16px;
}
}



@media (max-width: 1024px) {

.intro{
    margin-top: 65px;
}

.intro_top{
    height: 350px;
    margin-bottom: 100px;
}

.intro .title{
    margin-top: 50px;
}

.intro .title .title_name1{
    font-size: 50px;
}

.intro .title .title_name2{
    font-size: 18px;
}
.intro .title .title_name2 > span:last-child{
    margin: 0 20px;
    margin-bottom: 25px;
}
.intro .title .title_name3{
    line-height: 170%;
    font-size: 16px;
}
}


@media (max-width: 1000px) {
    
    .img_box{
        width: 500px;
        padding: 0 60px;
        box-sizing: border-box;
        
    }
    .img_box > img{
        width: 100%;
        border-radius: 20px;
    }
    .txt_box{
        width: 50%;
    }
    .txt_box span{
        color: #79A6AD;
    }
    .txt_box .txt_name{
        font-size: 22px;
        margin-bottom: 40px;
        line-height: 32px;
    }
    .txt_box .txt_detail{
        line-height: 170%;
        font-size: 15px;
    }
    }


@media (max-width: 768px) {


    /* main */

.breadCrumb{
    font-size: 12px;
}

.intro_main{
    width: 100%;
    margin-bottom: 70px;
}


.intro_main .txt_detail br {
    display: none;
}

.intro_box{
    display: block;
    align-items: center;
    margin-bottom: 100px;
}
.intro_box.rg .img_box{
    order: 0;
}

.intro_box.rg .txt_box{
    text-align: left;
}

.img_box{
    width: 300px;
    padding: 0px;
    box-sizing: border-box;
    margin-bottom: 50px;
    
}
.img_box > img{
    width: 100%;
    border-radius: 20px;
}
.txt_box{
    width: 100%;
}
.txt_box span{
    color: #79A6AD;
}
.txt_box .txt_name{
    font-size: 22px;
    margin-bottom: 30px;
    line-height: 32px;
}
.txt_box .txt_detail{
    font-size: 14px;
    line-height: 24px;
}

.btn_asks a{
    font-size: 14px;
}



}