@charset "utf-8";

.write .top {
    margin-top: 100px;
    width: 100%;
    height: 200px;
    background-color: #fff;

    position: relative;
}

.write .none {
    display: none;
}

.write .top h2 {
    font-size: 34px;
    font-family: 'NotoSerifKR';
    position: absolute;
    top: 50%;
    left :50%;
    transform: translate(-50%, -50%);
}

.write .write-frm {
    width: 100%;
}

.write .write-frm fieldset {
    width: 100%;
    
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    padding: 20px 0px;
    box-sizing: border-box;
}

.write .write-frm fieldset legend {
    display: none;
}
.write .write-frm fieldset .sheet {
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
}
.write .write-frm .sheet span {
    color: #f00;
}

.article_name{
    margin-top: 2%;
}

.write .write-frm fieldset .sheet:last-child {
    border-bottom: 0;
}

.write .write-frm label {
    font-size: 18px;
    display: inline-block;
    width: 150px;
}

.write select {
    width: 20%;
    height: 40px;
    font-family: "Pretendard-Medium";
    font-size: 16px;

    border: 1px solid #ccc;

    font-family: "Pretendard-Medium";
    padding: 0 15px;
    margin-right: 25px;
    color: #000;
    
    font-size: 16px;
}

.write select:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;
}
.write input {
    width: 50%;
    height: 40px;
    border: 1px solid #ccc;
    padding: 0 15px;
    font-size: 16px;

    color: #999;

    font-family: "Pretendard-Medium";
}
.write input[type="file"] {
    position: relative;

    vertical-align: middle;
    padding-top: 8px;
    box-sizing: border-box;
    padding-bottom: 8px;
    margin-bottom: 10px;
}

.image2 {
    margin-top: 20px;
}

.write input::placeholder {
    color: #ccc;
}
.write input[type="url"] {
    color: #333;
}

.write input[type="file"]::-webkit-file-upload-button {
    background-color: #79a6ad;
    outline: none;
    border: none;
    border-radius: 30px;
    padding: 5px 15px;

    font-family: "Pretendard-Medium";
    color: #fff;
    cursor: pointer;

    font-size: 14px;
    
    position:absolute;
    top: 5px;
    right: 20px;
}






.write input[type="text"] {
    width: 9%;
    margin-right: 25px;
}
.write input[id="title"] {
    width: 50%;
    height: 40px;
    border: 1px solid #ccc;
    padding: 0 15px;
    font-size: 16px;
    color: #333;

    font-family: "Pretendard-Medium";
}


.write input:focus {
    outline: none;
    box-shadow: none;
    border-color: inherit;
}

.write textarea {
    width: 80%;
    height: 350px;
    border: 1px solid #ccc;
    vertical-align: top;
    font-size: 16px;

    resize: none;
    font-family: "Pretendard-Medium";
}

.write textarea:focus {
    
    outline: none;
    box-shadow: none;
    border-color: inherit;
}

.write .btn-wrap {
    margin: 40px 0 80px;
    width: 100%;
    text-align: center;
}
.write button.complete {
    margin-right: 10px;
    color: #fff;
    font-size: 18px;
    font-family: "Pretendard-Medium";

    cursor: pointer;
    padding: 8px 25px;

}

.write button.cancel {
    margin-left: 10px;
    color: #333;
    font-size: 18px;

    font-family: "Pretendard-Medium";

    cursor: pointer;

    background-color: #ccc;
    padding: 8px 20px;
}
.write .top .notice {
    font-size: 12px;
    color: #f00;

    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    font-size: 14px;
}

.write button[type="button"] {
    width: 35px;
    height: 35px;
    /* outline: 1px solid red; */

    padding: 0;
    margin-left: 20px;
    vertical-align: top;
    margin-top: 2px;

    background-color: #fff;
    background-image: url('../img/plus.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;

    cursor: pointer;
}



.write .preview {
    margin-left: 150px;
    margin-top: 30px;

    width: 50%;
    border: 1px solid #ccc;
}

.write .preview img {
    max-width: 50%;
    width: 40%;
    display: inline-block;
}

.img_preview {
    display: flex;
}

.img_preview div {
    display: flex;
    width: calc(100% - 235px);
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid #ccc;
    justify-content: center;
}

.img_preview div img {
    display: block;
    max-width: 50%;
    max-height: 300px;
    margin-left: 10%;
    margin-right: 10%;
}


.write button.delete {
    background-image: none;
    font-family: "Pretendard-Medium";
    font-size: 15px;
    background-color: rgb(201, 55, 74);
    color: #fff;
    border-radius: 30px;
    width: auto;
    height: auto;
    padding: 8px 20px;
}


.sheet2 {
    display: flex;
    align-items: center;
    gap: 20px;
}

.sheet2 p {
}

.img-revise-btn1 {
    width: 100px !important;
    background-image: none !important;
    background-color: #79a6ad !important;
    color: #fff !important;
    font-family: "Pretendard-Medium";
    font-size: 16px;
}
.img-revise-btn2 {
    width: 100px !important;
    background-image: none !important;
    background-color: #79a6ad !important;
    color: #fff !important;
    font-family: "Pretendard-Medium";
    font-size: 16px;
}