@charset "utf-8";

.notfound {
    margin-top: 100px;
    margin-bottom: 80px;
}

.warnning {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.warnning img {
    margin-top: 30px;
    margin-bottom: 35px;
}

.warnning .page_404 {
    font-size: 18px;
    margin-bottom: 10px;
}

.warnning h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.warnning .txt {
    font-size: 16px;
    line-height: 26px;
    color: #999;
    margin-bottom: 70px;
}

.warnning a {
    color: #fff;
    background-color: #79a6ad;
    text-decoration: none;

    padding: 10px 25px;
    border-radius: 30px;
    font-size: 18px;

    transition: all 0.4s;
}