@charset "utf-8";

@font-face {
	font-family: "Pretendard-Medium";
	src: url('../font/Pretendard-Medium.eot') format('embeded-opentype'),
		url('../font/Pretendard-Medium.woff') format('woff');
}

@font-face {
    font-family: 'NotoSerifKR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NotoSerifKR.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


body {
	font-family: "Pretendard-Medium";
    color: #333;
}

.inner {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.con_tit h2 {
    font-size: 36px;

    margin-bottom: 30px;
    display: inline-block;

}

.con_tit p {
    word-break: keep-all;

    font-size: 18px;
    line-height: 28px;
}

/* 버튼디자인 */

button {
    padding: 10px 25px;
    background-color: #79a6ad;
    outline: none;
    border: none;
    border-radius: 30px;
}

button a {
    color: #fff;
    text-decoration: none;
    font-family: "Pretendard-Medium";
}

/* 태블릿 */

@media (max-width: 1023px) {
    .con_tit h2 {
        font-size: 24px;
        margin-bottom: 20px;
        line-height: 32px;
    
    }
    
    .con_tit p {
        word-break: keep-all;
    
        font-size: 14px;
        line-height: 24px;
    }

}

/* 모바일 */
@media (max-width: 500px) {
    .con_tit h2 {
        font-size: 22px !important;
        margin-bottom: 20px;
        line-height: 32px;
    
    }
}