/* header.css */

@charset "utf-8";

body.on {
    overflow: hidden;
}

header {
    width: 100%;
    height: 100px;
    background-color: transparent;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;

    box-sizing: border-box;

    transition: all 0.3s;
}

header .inner {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

header .header_logo {
    width: 300px;
    height: 50px;
    margin-top: 25px;
    position: absolute;
    left: 0;
    text-indent: -9999px;
}

header .header_logo a {
    display: block;
    width: 100%;
    height: 100%;

    transition: all 0.3s;
}

header .header_logo a.logo_on {
    background: url('../img/header_logo_on.png');

}

header .header_logo a.logo_off {
    background: url('../img/header_logo_off.png');
}

header.sub .header_logo a.logo_on {
    background: url('../img/header_logo_on.png');
}


header .gnb {
    position: absolute;
    right: 0;
}

header .gnb>.gnb_1depth>li {
    float: left;
    line-height: 100px;

    position: relative;
}

header .gnb>.gnb_1depth>li::before {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 3px;
    left: 50%;
    background-color: #79a6ad;
    transition: all 0.3s ease-in-out;
    transform: translateX(-65%);
}

header .gnb>.gnb_1depth>li:nth-child(1):before {
    top: 96px;
}

header .gnb>.gnb_1depth>li:nth-child(4):before {
    top: 96px;
}

header .gnb>.gnb_1depth>li:hover::before {
    width: 80%;
}

header .gnb>.gnb_1depth>li>a {
    text-align: center;

    color: #fff;
    text-decoration: none;

    font-size: 22px;
    font-size: 1.375rem;

    font-weight: 400;

    margin-right: 30px;
    /* outline: 1px solid red; */
}

header .gnb .gnb_2depth {
    position: absolute;
    top: -3px;
    left: -23%;

    line-height: 50px;
    width: 120%;

    position: relative;

    height: 0;

    opacity: 0;
    transition: all 0.4s;
    pointer-events: none;
}

header .gnb .gnb_1depth_li .gnb_2depth.active {
    display: block;
    opacity: 1;
    pointer-events: auto;
}

/* header .gnb .gnb_2depth::before {
    content: "";
    display: block;
    width: 70%;
    height: 4px;
    background-color: #79a6ad;

    position: absolute;
    top: -4px;
    left: 50%;

    transform: translateX(-50%);

} */

header .gnb .gnb_2depth li {
    text-align: center;
}

header .gnb .gnb_2depth li a {
    color: #333;
    text-decoration: none;
    background-color: #eee;

    font-size: 18px;

    display: block;
    box-sizing: border-box;
}

header .gnb .gnb_2depth li a:hover {
    color: #79a6ad;
}

header .menu_btn {
    display: none;
    position: absolute;
    top: 15px;
    right: 0%;
    z-index: 99999;
    width: 70px;
    height: 70px;
    cursor: pointer;
    box-sizing: border-box;
    /* background-color: #ccc; */
}

header .menu_btn span {
    position: absolute;
    left: 18px;
    width: 47px;
    height: 3px;
    background-color: #79a6ad;
    transition: 0.3s;
}

header .menu_btn span:nth-child(1) {
    top: 23px;
    width: 37px;
}

header .menu_btn span:nth-child(2) {
    margin-top: -1px;
    top: 50%;
}

header .menu_btn span:nth-child(3) {
    bottom: 23px;
    width: 30px;
}

/**************** 반응형 **********************/


/* 태블릿, 모바일 공통 */

@media (max-width: 1024px) {

    
    header {
        height: 65px;
        background-color: #fff !important;
    }
    
    header .header_logo {
        width: 300px;
        height: 35px;
        position: absolute;
        left: 0;
        text-indent: -9999px;
        margin-top: 15px;
    }
    
    header .header_logo a {
        display: block;
        width: 100%;
        height: 100%;
    }

    header .header_logo a.logo_on {
        background: url('../img/header_logo_on.png');
        background-repeat: no-repeat;
        background-size: 55%;
        background-position: left center;
    }
    
    header .header_logo a.logo_off {
        background: url('../img/header_logo_on.png');
        background-repeat: no-repeat;
        background-size: 55%;
        background-position: left center;
    }
    
    header.sub .header_logo a.logo_on {
        background: url('../img/header_logo_on.png');
        background-repeat: no-repeat;
        background-size: 55%;
        background-position: left center;
    }

    header .gnb {
        position: relative;
        top: 65px;
        background-color: #fff;
    }

    header .gnb>.gnb_1depth {
        display: none;
        transition: all 0.4s;
    }

    header .gnb>.gnb_1depth.on {
        display: block;

        transition: all 0.4s;
    }

    header .gnb>.gnb_1depth >li {

        float: none;
        line-height: 65px;
        padding-left: 0%;
        
        position: relative;
    }

    header .gnb>.gnb_1depth >li > a {
        display: block;

        color: #333 !important;
        text-align: left;
        border-bottom: 1px solid #79a6ad;

        font-size: 20px;
    }

    header .menu_btn {
        display: block;
        
        margin-top: 3px;
        right: 15px;
        width: 34px;
        height: 30px;
        border: 0 none;
    }

    header .menu_btn span {
        position: absolute;
        left: 0;
        width: 100% !important;
        height: 3px;
        border-radius: 3px;

        transition: 0.3s;
    }

    header .menu_btn span:nth-child(1) {
        top: 2px;
    }

    header .menu_btn.on span:nth-child(1) {
        transform: rotate(45deg);
        top: 47%;
        background-color: #79a6ad;
    }

    header .menu_btn span:nth-child(2) {
        margin-top: -1px;
        top: 50%;
    }

    header .menu_btn.on span:nth-child(2) {
        opacity: 0;
    }

    header .menu_btn span:nth-child(3) {
        bottom: 2px;
    }

    header .menu_btn.on span:nth-child(3) {
        transform: rotate(-45deg);
        bottom: 45%;
        background-color: #79a6ad;
    }

    header .mobile_menu_bg {
        display: none;
    }

    header .mobile_menu_bg.on {
        display: block;
        position: absolute;
        top: 0;

        width: 100%;
        height: 100vh;
        background-color: #fff;
    }


    /* 2depth */
    header .gnb .gnb_2depth {
        position: static;
    
        line-height: 50px;
        width: 100%;
        border: none;

        display: block;
        height: auto;
        opacity: 1;

        pointer-events: auto;
    }
    
    header .gnb .gnb_2depth li {
        text-align: left;
    }
    
    header .gnb .gnb_2depth li a {
        color: #333;
        background-color: #fff;

        width: 95%;
        padding-left: 5%;

        font-size: 16px;
        border-bottom: 1px solid #eee;
    }
    
    header .gnb .gnb_2depth li a:hover {
        color: #333;
    }

    header .gnb>.gnb_1depth>li::before {
        display: none;
    }




}
    