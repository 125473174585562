@charset "utf-8";

article.admin_list {
    margin-top: 100px;
    min-height: 74vh;
}
article.admin_list .inner {
    width: 1200px;
    max-width: 1200px;
}

article.admin_list .tit_box {
    position: relative;
}

article.admin_list .tit_box h2 {
    font-family: "NotoSerifKR";
    font-size: 40px;
    margin-bottom: 60px;
    margin-left: 20px;
}

article.admin_list .tit_box h2::before {
    display: inline-block;
    content: "";
    width: 5px;
    height: 42px;
    background: #79A6AD;
    position: absolute;
    bottom: -5px;
    left: 0;
}

article.admin_list .tit_box .loginbox {
    position: absolute;
    top: 60%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

article.admin_list .tit_box .loginbox p {
    font-size: 18px;
    margin-right: 20px;

}
article.admin_list .tit_box .loginbox button {
    font-family: "Pretendard-Medium";
    padding: 0;
    padding: 5px 15px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all 0.4s;
}
article.admin_list .tit_box .loginbox button:hover {
    background-color: #76c3c5;
}
.pannel {
    width: 100%;
    display: flex;
    margin-bottom: 30px;

    position: relative;
}
.pannel div {
    margin-right: 30px;
}

.pannel div span {
    margin-right: 20px;
    font-size: 18px;
}

.pannel div select {
    font-family: "Pretendard-Medium";
    font-size: 16px;
    width: 100px;
    padding-left: 10px;
    
    border: 1px solid #ccc;
    height: 26px;
}

.pannel div input {
    font-family: "Pretendard-Medium";
    font-size: 16px;
    width: 130px;
    padding-left: 10px;

    border: 1px solid #ccc;
}

.pannel .hypen {
    margin: 0 10px;
}


.pannel .title span {
    margin-right: 40px;
}
.pannel .title input {
    width: 250px;
    margin-left: -20px;
}

.pannel .search_btn {
    font-size: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    border-left: none;
    background: url('../img/search_icon.png') no-repeat top/cover;
    vertical-align: top;
}
.pannel .write  {
    position: absolute;
    right: 0;
    margin-right: 0;
}

.pannel .write button {
    font-family: "Pretendard-Medium";
    padding: 5px 20px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

/* 테이블 디자인 */

.list {
    max-width: 100%;
    border: 1px solid #eee;
    border-bottom: none;
    margin-bottom: 50px;
    table-layout: fixed;
    overflow: hidden;
}

.list table {
    width: 100%;
    text-align: left;
}

.list table .number {
    text-align: center;
}

.list table thead {
    background-color: #eee;
}
.list table thead th, .list table tbody td {
    padding: 10px;
}

.list table tbody tr {
    border-bottom: 1px solid #eee;
    position: relative;
}

/* 너비 및 워드 랩 설정 */
    
  .list th:nth-child(1),
  .list td:nth-child(1) {
    width: 5%;
    text-align: center;;
  }
  
  .list th:nth-child(2),
  .list td:nth-child(2) {
    width: 10%;
  }
  
  .list th:nth-child(3),
  .list td:nth-child(3) {
    width: 8%;
  }
  
  .list th:nth-child(4),
  .list td:nth-child(4) {
    width: 42%;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: absolute;
    width: 40%;
    max-width: 40%;

    box-sizing: border-box;
    padding: 10px;
  }
  .list td:nth-child(4) {
    margin-top: 6px;
  }
  
  .list th:nth-child(5),
  .list td:nth-child(5) {
    width: 6%;
  }
  
  .list th:nth-child(6),
  .list td:nth-child(6) {
    width: 12%;
  }
  
  .list th:nth-child(7),
  .list td:nth-child(7) {
    width: 14%;
    text-align: center;
  }



/* 테이블 디자인 */

.list table tbody td .revise {
    font-family: "Pretendard-Medium";
    padding: 5px 20px;
    font-size: 14px;
    color: #fff;
    margin-right: 10px;

    cursor: pointer;
}
.list table tbody td .delete {
    font-family: "Pretendard-Medium";
    padding: 5px 20px;
    font-size: 14px;
    background-color: #ccc;

    cursor: pointer;
}

.list table tbody td a {
    cursor: pointer;
    color: #333;
    text-decoration: none;
}
.list table tbody td a:hover {
    text-decoration: underline;
}

.pg_wrap {
    display: flex;

    align-items: center;
    justify-content: center;

    margin-bottom: 80px;
}

.pg_wrap div {
    width: 25px;
    height: 25px;
    background-color: #eee;
    text-align: center;
    border-radius: 5px;
    margin-right: 10px;
    line-height: 25px;

    cursor: pointer;
}

.pg_wrap div.active {
    background-color: #79A6AD;
    color: #fff;
}

.pg_wrap .prev_btn {
    font-size: 0;
    background: url('../img/pager_prev.png') no-repeat top/cover;
    background-color: #eee;
}

.pg_wrap .next_btn {
    font-size: 0;
    background: url('../img/pager_next.png') no-repeat top/cover;
    background-color: #eee;
}
button.ask {
    margin-right: 20px;
}

button.ask, button.reset {
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    color: #79A6AD;
    background: none;
    border: none;
}

.None{
    padding: 5%;
    text-align: center;
    font-size: 40px
}







@media (max-width: 1280px) {
    article.admin_list .inner {
        width: 1200px;
        max-width: 1200px;
    }
}
@media (max-width: 865px) {
    
    article.admin_list .inner {
        width: 1200px;
        max-width: 1200px;
    }
}

@media (max-width: 767px) {
    article.admin_list .inner {
        width: 1200px;
        max-width: 1200px;
    }
}